import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import firebase from "firebase";
import store from "@/store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/panel",
    name: "panel",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "carrito" */ "../views/panel.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/miempresa",
    name: "miempresa",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "carrito" */ "../views/miempresa.vue"),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.moduloempresa) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/accesos_usuarios/:id/",
    name: "accesos_usuarios",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "carrito" */ "../views/accesos_usuarios.vue"),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.moduloempresa) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/recepcion_monturas/",
    name: "recepcion_monturas",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/kardex/recepcion_monturas.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.modulocaja) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/almacen_accesorios/",
    name: "almacen_accesorios",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/kardex/almacen_accesorios.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.modulocaja) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/envio_accesorios/",
    name: "envio_accesorios",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/kardex/envio_accesorios.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.modulocaja) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/almacen_monturas/",
    name: "almacen_monturas",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/kardex/almacen_monturas.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.modulocaja) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/envio_monturas/",
    name: "envio_monturas",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/kardex/envio_monturas.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.modulocaja) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/traslado_monturas/",
    name: "traslado_monturas",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/kardex/traslado_monturas.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.modulocaja) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/movimientos_kardex/",
    name: "movimientos_kardex",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/kardex/movimientos_kardex.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.modulocaja) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/venta_general/",
    name: "venta_general",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/reportes/venta_general.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.moduloproductos) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/reg_contables/",
    name: "reg_contables",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/reportes/reg_contables.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.moduloproductos) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/comisiones/",
    name: "comisiones",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/reportes/comisiones.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.moduloproductos) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/proformas",
    name: "proformas",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "carrito" */ "../views/venta/proformas.vue"),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.moduloclientes) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/reporte_flujos",
    name: "reporte_flujos",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/venta/reporte_flujos.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.moduloclientes) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/flujo_caja",
    name: "flujo_caja",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "carrito" */ "../views/venta/flujo_caja.vue"),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.moduloclientes) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/panel_asistencia",
    name: "panel_asistencia",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/asistencia/panel_asistencia.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.moduloclientes) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/pendientes",
    name: "pendientes",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "carrito" */ "../views/venta/pendientes.vue"),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.moduloclientes) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/rep_garantias",
    name: "rep_garantias",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/venta/rep_garantias.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.moduloclientes) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/ventas",
    name: "ventas",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "carrito" */ "../views/venta/venta.vue"),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.moduloclientes) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/optometria",
    name: "optometria",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/optometria/optometria.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.optometria) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/reporte_parametros",
    name: "reporte_parametros",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/optometria/reporte_parametros.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.modulocaja) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/reporte_recetas",
    name: "reporte_recetas",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/optometria/reporte_recetas.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.optometria) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/lista_pedidos",
    name: "lista_pedidos",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/pedidos/lista_pedidos.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.pedidos) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/garantias",
    name: "garantias",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/pedidos/garantias.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.pedidos) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/bicelados",
    name: "bicelados",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/pedidos/bicelados.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.pedidos) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/paneladmin",
    name: "paneladmin",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "carrito" */ "../views/paneladmin.vue"),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.paneladmin) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/panelgeneral",
    name: "panelgeneral",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/gestorempresas/panelgeneral.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.paneladmin) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/gestionEmpresas/:bd",
    name: "gestionEmpresas",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/gestorempresas/gestionEmpresas.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.paneladmin) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/categorias",
    name: "categorias",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "carrito" */ "../views/categorias.vue"),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.moduloproductos) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/lista_clientes/",
    name: "lista_clientes",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/clientes/lista_clientes.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.moduloclientes) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/historico_ventas/",
    name: "historico_ventas",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/clientes/historico_ventas.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.modulocaja) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/mensajeria_sms/",
    name: "mensajeria_sms",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/clientes/mensajeria_sms.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.moduloclientes) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/panel_mensajes",
    name: "panel_mensajes",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/mensajeria/panel_mensajes.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.modulocaja) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/inventario_monturas",
    name: "inventario_monturas",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/almacen/inventario_monturas.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.modulocaja) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/lista_precios",
    name: "lista_precios",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/almacen/lista_precios.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.modulocaja) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/monturas",
    name: "monturas",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "carrito" */ "../views/almacen/monturas.vue"),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.modulocaja) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/lentes_contacto",
    name: "lentes_contacto",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/almacen/lentes_contacto.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.modulocaja) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/accesorios",
    name: "accesorios",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/almacen/accesorios.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.modulocaja) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/productos",
    name: "productos",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/almacen/productos.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.modulocaja) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },

  {
    path: "/comprobantes",
    name: "comprobantes",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "carrito" */ "../views/comprobantes.vue"),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.modulocaja) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/reportes",
    name: "reportes",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/reportes/reportes.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.moduloreportes) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/boletaFactura",
    name: "boletaFactura",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/sunat/boletaFactura.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.modulosunat) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/nuevaBaja",
    name: "nuevaBaja",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "carrito" */ "../views/sunat/nuevaBaja.vue"),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.modulosunat) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/nuevaNCredito",
    name: "nuevaNCredito",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/sunat/nuevaNCredito.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.modulosunat) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/creditoDebito",
    name: "creditoDebito",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/sunat/creditoDebito.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.modulosunat) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/resumenbaja",
    name: "resumenbaja",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/sunat/resumenbaja.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.modulosunat) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/ticket",
    name: "ticket",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "carrito" */ "../views/sunat/ticket.vue"),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.modulosunat) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/ImportaExporta",
    name: "ImportaExporta",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "carrito" */ "../views/ImportaExporta.vue"),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.moduloproductos) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/buscardocumentos",
    name: "buscardocumentos",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/vistasLibres/buscardocumentos.vue"
      ),
  },
  {
    path:
      "/comprobantes_clientes/:bd/:ruc/:tipodoc/:serie/:correlativo/:cliente",
    name: "comprobantes_clientes",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/vistasLibres/comprobantes_clientes.vue"
      ),
  },
  {
    path: "/recetas_externas/:id_receta",
    name: "recetas_externas",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/vistasLibres/recetas_externas.vue"
      ),
  },
  {
    path: "/visualiza_proforma/:bd/:id_proforma",
    name: "visualiza_proforma",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/vistasLibres/visualiza_proforma.vue"
      ),
  },
  {
    path: "/suministros",
    name: "suministros",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/vistasLibres/suministros.vue"
      ),
  },
  {
    path: "/gestiontiendas",
    name: "gestiontiendas",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/configuracion/tiendas"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.moduloempresa) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/gestionUsuarios",
    name: "gestionUsuarios",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "carrito" */ "../views/gestionUsuarios.vue"),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.moduloempresa) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/reporteventa",
    name: "reporteVentaDetalle",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/reportes/reporteVentaDetalle.vue"
      ),
    meta: {
      requireAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
    const user = firebase.auth().currentUser;
    if (user) {
      next();
    } else {
      next({
        name: "Home",
      });
    }
  } else {
    next();
  }
});

export default router;
